var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasManager
      ? _c(
          "div",
          { staticClass: "Manager", class: { colorInverse: _vm.colorInverse } },
          [
            !_vm.mobile
              ? _c("div", { staticClass: "Manager-desktop" }, [
                  _c("div", { staticClass: "Manager-contacts" }, [
                    _c(
                      "a",
                      { attrs: { href: `mailto:${_vm.manager.email}` } },
                      [_vm._v(_vm._s(_vm.manager.email))]
                    ),
                    _vm._v(" "),
                    _c("a", { attrs: { href: `tel:${_vm.manager.phone}` } }, [
                      _vm._v(_vm._s(_vm._f("phone")(_vm.manager.phone))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "Manager-name" }, [
                    _c("span", { staticClass: "small" }, [
                      _vm._v("Ваш менеджер"),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.manager.full_name))]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.mobile
              ? _c("div", { staticClass: "Manager-mobile" }, [
                  _c("div", { staticClass: "Manager-name" }, [
                    _c("span", { staticClass: "small" }, [
                      _vm._v("Ваш менеджер"),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.manager.full_name))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "Manager-contacts" }, [
                    _c(
                      "a",
                      { attrs: { href: `mailto:${_vm.manager.email}` } },
                      [_vm._v(_vm._s(_vm.manager.email))]
                    ),
                    _vm._v(" "),
                    _c("a", { attrs: { href: `tel:${_vm.manager.phone}` } }, [
                      _vm._v(_vm._s(_vm._f("phone")(_vm.manager.phone))),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }