var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.display,
          expression: "display",
        },
      ],
      ref: "modal",
      staticClass: "ModalMobile",
    },
    [
      _c("div", { staticClass: "ModalMobile-header" }, [
        _c(
          "div",
          { staticClass: "ModalMobile-close", on: { click: _vm.close } },
          [_c("img", { attrs: { src: "/images/close.svg" } })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ModalMobile-title" }, [_vm._t("header")], 2),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ModalMobile-body" }, [
        _c(
          "div",
          { staticClass: "mobile-padding-fix" },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }